import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import LinkButton from '../components/LinkButton';
import PlayerCounter from '../components/PlayerCounter';
import PlayerRow from '../components/PlayerRow';
import PointSetter from '../components/PointSetter';
import VariantRules from '../components/VariantRules';
import { breakpoints, fonts } from '../constants/variables';
import { useGameStore } from '../stores/gameStore';
import { usePlayerStore } from '../stores/playerStore';
import { getSaveKey, titleText } from '../utils/helpers';
import MiscPoints from '../components/MiscPoints';
import Checkbox from '../components/Checkbox';

const Players = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 30rem);
  grid-gap: 1rem;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const Div = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoBack = styled(LinkButton)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const H1 = styled.h1`
  font-family: ${fonts.title};
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;

  span {
    font-family: ${fonts.fancy};
    font-weight: 300;
    text-transform: none;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

const New = () => {
  titleText('Create a New Game - ti4score');
  const [players, playerCount, chosenFactions, initPlayers] = usePlayerStore(
    (state) => [
      state.players,
      state.playerCount,
      state.chosenFactions,
      state.initPlayers,
    ]
  );
  const [
    setGameStarted,
    setSaveKey,
    gameStarted,
    showDiscordant,
    toggleDiscordant,
  ] = useGameStore((state) => [
    state.setGameStarted,
    state.setSaveKey,
    state.gameStarted,
    state.showDiscordant,
    state.toggleDiscordant,
  ]);

  const chosenFactionsArray = Object.entries(chosenFactions);
  // for some reason an undefined key is being added to our chosenFactions hashmap
  // when the hashmap is converted to an array of keys & values, the keys become strings
  // so filtering out a key that is the string 'undefined'. We also only want to count factions with a value of true
  // as that means the faction was chosen by a player
  let navigate = useNavigate();
  let chosenFactionCount = 0;
  for (let i = 0; i < chosenFactionsArray.length; i++) {
    if (
      chosenFactionsArray[i][0] !== 'undefined' &&
      chosenFactionsArray[i][1] === true
    ) {
      chosenFactionCount += 1;
    }
  }

  // toggle the discordant factions
  const setToggleDiscordant = () => {
    toggleDiscordant();
    initPlayers(playerCount);
  };

  const handleStartGame = () => {
    if (gameStarted) return;
    const saveKey = getSaveKey(playerCount);
    setSaveKey(saveKey);
    setGameStarted(true);
    navigate('/play', { replace: true });
  };

  return (
    <>
      <H1>
        <span>Begin a New Game</span>
      </H1>
      <GoBack outline size="sm" square to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
      </GoBack>
      <PlayerCounter />
      <PointSetter />
      <section aria-label="Player List">
        <Div>
          <Checkbox
            id="discordant"
            label="Include Unofficial Factions (Discordant Stars, etc)"
            helpText="This will display any available homebrew factions in the lists below"
            checked={showDiscordant}
            onChange={setToggleDiscordant}
          />
        </Div>
        <Players>
          {players.map((player, index) => {
            return <PlayerRow key={`player_${index}`} id={index} />;
          })}
        </Players>
      </section>
      <section aria-label="Game Start">
        <Button
          onClick={handleStartGame}
          disabled={chosenFactionCount !== playerCount}
        >
          {chosenFactionCount !== playerCount &&
            playerCount !== undefined &&
            'Choose Your Factions'}
          {playerCount === undefined && 'Choose the # of Players'}
          {chosenFactionCount === playerCount && 'Begin Game'}
        </Button>
      </section>
      <VariantRules />
      <MiscPoints />
    </>
  );
};

export default New;
